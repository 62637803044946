import { IonButton, IonContent, IonFooter, IonGrid, IonPage, IonRow, IonText, IonTitle, IonToolbar} from '@ionic/react';
import ShippingTimelineCard from '../components/ShippingTimelineCard';
import VirtualInstallInfoCard from '../components/VirtualInstallInfoCard';
import Toolbar from '../components/ToolBar';
import React  from 'react';
import './Home.css';

const Home: React.FC = () => {
  return (
    <IonPage>
      <Toolbar />
      <IonContent fullscreen>    
      <br></br>
      <br></br>
      <br></br>

      <IonText style={{ textAlign: 'center' }} color='primary'>
        <h1><strong>Please place your order directly within ClinicianConnect. <br></br><br></br>Log in to ClinicianConnect, then navigate to Inventory &gt;&gt; Tablet &gt;&gt; Create Order. <br></br><br></br>If you need help, please contact HRS Support at 551-203-0011 or by emailing support@healthrecoverysolutions.com </strong></h1>
      </IonText>
      </IonContent>
    </IonPage>
  );
};

export default Home;
